<template>
    <div class="elem">
        <div class="site-wrap" id="home-section">
            <topHeader/>
            <PreLoader v-if="isLoading"></PreLoader>
            <v-card
                class="elem__cards"
            >
                <v-card-title>
                    <v-row>
                        <v-col md="6" cols="6">
                            <!--<h5>{{item.title}}</h5>-->
                        </v-col>
                        <v-col md="6" cols="6" align="end">
                            <v-btn style="color:#fff;" color="#f0bd30" @click="goTo">Артка кайтуу</v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-container fluid>
                    <v-row dense>
                        <v-col
                        md="12" sm="12" xs="12" cols="12"
                        
                        >
                            <v-card>
                                <!-- <v-img
                                :src="card.src"
                                class="white--text align-end"
                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                
                                > </v-img>-->                
                                <v-card-text>
                                    <h4 style="margin:10px;font-weight:600;color:#000;">{{item.textTitle}}</h4>
                                    <span style="font-size:18px; margin:10px;padding:10px;">{{item.text}}</span>
                                    <section class="container">
                                        <!--questionBox-->
                                        <div class="questionBox">
                                            <!-- transition -->
                                            <transition :duration="{ enter: 500, leave: 300 }" enter-active-class="animated zoomIn" leave-active-class="animated zoomOut" mode="out-in">
                                                <!--qusetionContainer -->
                                                <div class="questionContainer" v-if="questionIndex<item.questions.length" :key="questionIndex">
                                                    <header>
                                                        <h1 class="title is-6">{{item.title}}</h1>
                                                        <!--progress-->
                                                        <div class="progressContainer">
                                                            <progress class="progress is-info is-small" :value="(questionIndex/item.questions.length)*100" max="100">{{(questionIndex/item.questions.length)*100}}%</progress>
                                                            <p>{{(questionIndex/item.questions.length)*100}}% complete</p>
                                                        </div>
                                                        <!--/progress-->
                                                    </header>
                                                    <!-- questionTitle -->
                                                    <h2 class="titleContainer title">{{ item.questions[questionIndex].text }}</h2>
                                                    <!-- quizOptions-->
                                                    <div class="optionContainer">
                                                        <div class="option" v-for="(response, index) in item.questions[questionIndex].responses" :key="index" :class="{ 'is-selected': userResponses[questionIndex] == index}" @click="selectOption(index)"  >
                                                            {{ index | charIndex }}. {{ response.title }}
                                                        </div>
                                                    </div>
                                                    <!--quizFooter: navigation and progress-->
                                                    <footer class="questionFooter">
                                                        <!--pagination-->
                                                        <nav class="pagination" role="navigation" aria-label="pagination">
                                                            <!-- back button -->
                                                            <a class="button orange" style="color:#000;" :disabled="questionIndex < 1" @click="prev" >
                                                                Back
                                                            </a>
                                                            <!-- next button :disabled="questionIndex>=quiz.questions.length" :class="(userResponses[questionIndex]==0)?'':'is-active'"-->
                                                            <a class="button orange" :class="(userResponses[questionIndex]==null)?'':'is-active'" @click="next" :disabled="questionIndex>=item.questions.length">
                                                                {{ (userResponses[questionIndex]==null)?'Skip':'Next' }}
                                                            </a>
                                                        </nav>
                                                        <!--/pagination-->
                                                    </footer>
                                                    <!--/quizFooter-->
                                                </div>
                                                <!--/questionContainer-->
                                                <!--quizCompletedResult-->
                                                <div v-if="questionIndex >= item.questions.length" :key="questionIndex" class="quizCompleted has-text-centered">
                                                    <!-- quizCompletedIcon: Achievement Icon -->
                                                    <span class="icon">
                                                        <i class="fa" :class="score()>3?'fa-check-circle-o is-active':'fa-times-circle'"></i>
                                                    </span>
                                                    <!--resultTitleBlock-->
                                                    <h2 class="title" v-if="score()>7">
                                                        <svg id="Capa_1" enable-background="new 0 0 512 512" height="100" viewBox="0 0 512 512" width="100" xmlns="http://www.w3.org/2000/svg">
                                                            <g id="Medal_22_">
                                                                <g>
                                                                    <path d="m256 274.183c-24.814 0-45-20.186-45-45s20.186-45 45-45 45 20.186 45 45-20.186 45-45 45zm0-60c-8.262 0-15 6.724-15 15s6.738 15 15 15 15-6.724 15-15-6.738-15-15-15z" fill="#fabe2c"/>
                                                                </g>
                                                            </g>
                                                            <path d="m271 229.183c0 8.276-6.738 15-15 15v30c24.814 0 45-20.186 45-45s-20.186-45-45-45v30c8.262 0 15 6.723 15 15z" fill="#ff9100"/>
                                                            <g>
                                                                <g>
                                                                    <path d="m323.793 510.315-67.793-35.2-67.793 35.2c-5.01 2.593-11.133 2.168-15.732-1.187-4.6-3.34-6.914-8.979-5.977-14.59l12.51-75.337-54.404-53.599c-4.043-3.999-5.508-9.932-3.75-15.322 1.758-5.405 6.416-9.36 12.041-10.21l75.176-11.323 34.248-75.747c4.863-10.723 22.5-10.723 27.363 0l34.248 75.747 75.176 11.323c5.625.85 10.283 4.805 12.041 10.21 1.758 5.391.293 11.323-3.75 15.322l-54.404 53.599 12.51 75.337c.938 5.61-1.377 11.25-5.977 14.59-4.618 3.373-10.745 3.783-15.733 1.187z" fill="#fed843"/>
                                                                </g>
                                                                <path d="m323.793 510.315c4.988 2.595 11.115 2.186 15.732-1.187 4.6-3.34 6.914-8.979 5.977-14.59l-12.51-75.337 54.404-53.599c4.043-3.999 5.508-9.932 3.75-15.322-1.758-5.405-6.416-9.36-12.041-10.21l-75.176-11.323-34.248-75.747c-2.432-5.361-8.057-8.042-13.682-8.042v230.156z" fill="#fabe2c"/>
                                                                <g>
                                                                    <path d="m256 214.183c-1.611 0-3.193-.264-4.746-.776l-90-30c-6.123-2.037-10.254-7.764-10.254-14.224v-90c0-8.291 6.709-15 15-15h180c8.291 0 15 6.709 15 15v90c0 6.46-4.131 12.188-10.254 14.224l-90 30c-1.553.512-3.135.776-4.746.776z" fill="#ff6c6c"/>
                                                                </g>
                                                                <path d="m346 64.183h-90v150c1.611 0 3.193-.264 4.746-.776l90-30c6.123-2.036 10.254-7.764 10.254-14.224v-90c0-8.291-6.709-15-15-15z" fill="#e63950"/>
                                                                <g>
                                                                    <path d="m376 94.183h-240c-8.291 0-15-6.709-15-15v-64.183c0-8.291 6.709-15 15-15h240c8.291 0 15 6.709 15 15v64.183c0 8.291-6.709 15-15 15z" fill="#fabe2c"/>
                                                                </g>
                                                                <path d="m376 0h-120v94.183h120c8.291 0 15-6.709 15-15v-64.183c0-8.291-6.709-15-15-15z" fill="#ff9100"/>
                                                            </g>
                                                        </svg>
                                                        You did an amazing job!
                                                    </h2>
                                                    <h2 class="title" v-if="score() <4">
                                                        You did a poor job!
                                                    </h2>
                                                    <h2 class="title" v-if="score() >4">
                                                        You did a good job!
                                                    </h2>
                                                    <p class="subtitle">
                                                        Total score: {{ score() }} / {{ item.questions.length }}
                                                    </p>
                                                    <br>
                                                    <a class="button" @click="restart" style="border:1px solid orange;background:orange;color:#fff;">Пройти тест заново <i class="fa fa-refresh"></i></a>
                                                    <!--/resultTitleBlock-->
                                                </div>
                                                <!--/quizCompetedResult-->
                                            </transition>
                                        </div>
                                        <!--/questionBox-->
                                    </section>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
            <Footer/>
        </div>
    </div>
</template>
<script>
import topHeader from '../../views/navigation/topHeader';
import Footer from '../../views/navigation/Footer';
import PreLoader from '../../components/preloader/PreLoader';
import * as firebase from '@/firebase';
export default {
    components: {
      topHeader,
      Footer,
      PreLoader
    },
    data: () => ({
        sharedialog: false,
        isFav: false,
        isLoading:false,
        sharing: {
            url: 'https://zhyldyzacademy.kg',
            title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
            description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
            quote: 'The hot reload is so fast it\'s near instant. - Evan You',
            hashtags: 'vuejs,vite,javascript',
            twitterUser: 'youyuxi'
        },
        networks: [
            { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#EA4335', mar:'10px',pad:'6px' },
            { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2',mar:'10px',pad:'6px' },
            { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5',mar:'10px',pad:'6px'},
            { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff',mar:'10px',pad:'6px' },
            { network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b',mar:'10px',pad:'6px' },
            { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc',mar:'10px',pad:'6px' },
            { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2',mar:'10px',pad:'6px' },
            { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8',mar:'10px',pad:'6px' },
            { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366',mar:'10px',pad:'6px' },
        ],
        item:{},
        quiz: {},
        questionIndex: 0,
        userResponses: [],
        isActive: false,
        four:4
    }),
    filters: {
      charIndex: function(i) {
         return String.fromCharCode(97 + i);
      }
   },
    mounted() {
        this.getTest();
    },
    created() {},
    methods: {
        async getTest() {
            this.isLoading = true;
            console.log(this.$route.params.testId)
            this.item = await firebase.eReadingCollection.doc(this.$route.params.testId).get()
                .then((snapshot) => {
                    return (snapshot.data())  
			})
            //this.item.tId = this.$route.params.testId;
            setTimeout(()=>{
                this.isLoading = false;
            },400)
        },
        restart(){
            this.questionIndex=0;
            this.userResponses=Array(this.item.questions.length).fill(null);
		},
      selectOption(index) {
          this.userResponses.push(index);
      },
      next: function() {
         if (this.questionIndex < this.item.questions.length)
            this.questionIndex++;
      },

      prev: function() {
         if (this.item.questions.length > 0) this.questionIndex--;
      },
      // Return "true" count in userResponses
        score() {
            var score = 0;
            for (let i = 0; i < this.userResponses.length; i++) {
                if (
                typeof this.item.questions[i].responses[
                    this.userResponses[i]
                ] !== "undefined" &&
                this.item.questions[i].responses[this.userResponses[i]].isCorrect
                ) {
                score = score + 1;
                }
            }
            return score;

         //return this.userResponses.filter(function(val) { return val }).length;
        },
        goTo(){
            this.$router.push({ path: '/admin-page/elementary/reading'});
        }
    },
    
}
</script>
<style lang="scss" scoped>
.v-application a {
    color: #fff  !important;
    & span {
        margin:10px;
    }
}
$trans_duration: 0.3s;
$primary_color: #3D5AFE;

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");


.button {
   transition: $trans_duration;
}
.title,
.subtitle {
   font-family: Montserrat, sans-serif;
   font-weight: normal;
}
.animated {
   transition-duration: $trans_duration/2;
}

.container{
	margin: 0 0.5rem;
}

.questionBox {
	
	max-width: 40rem;
	width: 35rem;
	min-height: 26rem;
	background: #FAFAFA;
    position: relative;
    display: flex;
	border-radius: 0.5rem;
	overflow: hidden;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	
	header{
		background:rgba(0,0,0,0.025);
		padding: 1.5rem;
		text-align: center;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		
		h1{
			font-weight: bold;
			margin-bottom: 1rem !important;
		}
		.progressContainer {
       width: 80%;
			margin: 0 auto;
			>progress{
                margin:0;
                border-radius: 5rem;
                overflow: hidden;
                border:none;
                width:100%;
                
                color:$primary_color;
            &::-moz-progress-bar { background: $primary_color; }
            &::-webkit-progress-value { background: $primary_color; }
            }
            >p{
                margin:0;
                margin-top: 0.5rem;
            }
        }
	}
   .titleContainer {
        text-align: center;
        margin: 0 auto;
        padding: 1.5rem;

    }

   .quizForm {
      display: block;
      white-space: normal;

      height: 100%;
      width: 100%;

      .quizFormContainer {
         height: 100%;
         margin: 15px 18px;

         .field-label {
            text-align: left;
            margin-bottom: 0.5rem;
         }
      }
   }
   .quizCompleted {
      width: 100%;
      padding: 1rem;
        text-align:center;
        
        > .icon{
            color: #FF5252;
            font-size: 5rem;
            
            .is-active{
                color: #00E676;
            }
        }
   }
   .questionContainer {
      white-space: normal;

      height: 100%;
      width: 100%;

      .optionContainer {
         margin-top: 12px;
         flex-grow: 1;
         .option {
            border-radius: 290486px;
            padding: 9px 18px;
            margin: 0 18px;
            margin-bottom: 12px;
            transition: $trans_duration;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.05);
			color: rgba(0,0,0,0.85);
            font-size:18px;
            border: transparent 1px solid;

            &.is-selected {
               border-color: rgba(black,0.25);
               background-color: rgb(127, 197, 106);
               color:#fff;
               font-weight:600;
            }
            &:hover {
               background-color: rgba(0, 0, 0, 0.1);
            }
            &:active {
               transform: scaleX(0.9);
            }
         }
      }

      .questionFooter {
				background:rgba(0,0,0,0.025);
				border-top: 1px solid rgba(0,0,0,0.1);
         width: 100%;
         align-self: flex-end;

         .pagination {
            //padding: 10px 15px;
            margin: 15px 25px;
         }
      }
   }
}
.pagination{
	display: flex;
	justify-content: space-between;
}
.button{
	padding: 0.5rem 1rem;
	border: 1px solid rgba(0,0,0,0.25);
	border-radius: 5rem;
	margin: 0 0.25rem;
	
	transition:0.3s;
	
	&:hover{
		cursor: pointer;
		background: #ECEFF1;
		border-color:rgba(0,0,0,0.25);
	}
	&.is-active{
		background: $primary_color;
		color: white;
		border-color: transparent;
		
		&:hover{
			background: darken($primary_color,10%);
			
		}
	}
}

@media screen and (min-width: 769px) {
   .questionBox {
      align-items: center;
      justify-content: center;

      .questionContainer {
         display: flex;
         flex-direction: column;
      }
   }
}

@media screen and (max-width: 768px) {
   .sidebar {
      height: auto !important;
      border-radius: 6px 6px 0px 0px;
   }
}

</style>